import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import ElementUI from 'element-ui'
import axios from "./util/api";
import extraApi from "@/util/extra-api";
import VueParticles from 'vue-particles';
import vueButtonEffect from 'vue-button-effect';

// import echarts from "echarts";
// Vue.prototype.$echarts = echarts;
import 'element-ui/lib/theme-chalk/index.css'
import '../static/css/base.scss'
// import '../theme/index.css'
import BaiduMap from 'vue-baidu-map'

import { Message, Loading } from 'element-ui'
import scroll from 'vue-seamless-scroll'
import { Object } from "core-js";
import VCharts from 'v-charts'
import dataV from '@jiaminghi/data-view'
import VueQrcode from "@xkeshi/vue-qrcode";
import Vcomp from './components/index'
import echarts from 'echarts'

// import * as flexble from './utils/flexible'

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)

Vue.prototype.$echarts = echarts
Vue.use(Vcomp)
Vue.component('qrcode', VueQrcode);
Vue.use(scroll)
Vue.use(BaiduMap, {
  // ak: '8a85DsOD68SNnbuzgClwxpItrOmuAD6m'
  // ak: 'PnnulIGL2CkWWw4diQNyClD0IE2IsdsK'
  ak: 'piSYUrFTXubSAUZtRpRC4Awsuo726O3x'
  
})
Vue.use(ElementUI)

Vue.use(VCharts)
Vue.use(dataV)
Vue.use(VueParticles)
Vue.use(vueButtonEffect)
//引入图片查看器
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})



Vue.prototype.$extraApi = extraApi
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (from.path === '/login' || to.path === '/login' || to.path === '/admin/public' || to.path === '/admin/changePassword'
    || to.path === '/admin/accountUpdate' || to.path === '/admin/companyAdd' || to.path === '/admin/companyList'
    || to.path === '/admin/transfer' || to.path === '/admin/companyUpdate' || to.path === '/admin/tag' || to.path === '/admin/dictionary'
    || to.path === '/admin/dictionary/add' || to.path === '/admin/tag/add' || to.path === '/admin/goodsTrace' || to.path === '/admin/goodsFlow'
    || to.path === '/admin/areaBusiness' || to.path === '/admin/screen' || to.path === '/admin/signSupervisor' || to.path === '/admin/signSupervisorAdd'
    || to.path === '/admin/companySignList' || to.path === '/admin/appMenu/add' || to.path === '/admin/roleCompanyAdd' || to.path === '/admin/companyRoleAdd'
    || to.path === '/admin/goodsList/batchDetail' || to.path === "/admin/department/add" || to.path === "/admin/department/supervisorAdd"
    || to.path === "/admin/department/supervisor" || to.path === "/admin/product" || to.path === "/admin/reimbursement-manage" || to.path === "/admin/project-manage"
    || to.path === "/admin/drag-detail" || to.path === "/admin/consult-detail" || to.path === "/admin/evaluateChartCode" || to.path === "/admin/selection-setting" || to.path === "/admin/iot-mode-list"
    || to.path === "/admin/task-list" || to.path === "/admin/iot-mode-manage" || to.path === "/admin/contract-manage" || to.path === "/admin/contract-preview" || to.path === "/admin/contract-list"
    || to.path === "/admin/adminCamList" || to.path === "/admin/AIModelList" || to.path === "/admin/AIModelCompanyList"||to.path === "/admin/h5Template"||to.path === "/admin/export-template"
  ) {
    // console.log(to.path);
    next()
  } else {
    // console.log(localStorage.getItem("company_id"), 123);
    if (to.path === "/admin/sensorFuSunScreen" && localStorage.getItem("company_id") == 2582225) {
      next()
    } else if (to.path === "/admin/sensorPingWuScreen" && localStorage.getItem("company_id") == 2582275) {
      next()
    } else if (to.path === "/admin/sensorzhouShanScreen" && localStorage.getItem("company_id") == 2582294) {
      next()
    } else if (to.path === "/admin/sensorDanLingScreen" && localStorage.getItem("company_id") == 2582269) {
      next()
    }  else {
      // console.log('获取菜单',localStorage.getItem("company_id"));
      axios.get('/pc/user-role/menu').then((v) => {
        let menu_list = []
        if (localStorage.getItem("company_id") == 'undefined') {
          // console.log('超级管理员');
          v.data.menu_list.forEach(item => {
            item.menu_list.forEach((item1, index) => {
              menu_list.push(item1)
            })
          })
        } else {
          menu_list = v.data.menu_list.map((v) => {
            // console.log(localStorage.getItem("company_id"), '测试舟山A')
            let data = {
              ...v,
              menu_list: v.menu_list
                .map((item) => {
                  let menu_list = null;
                  // console.log(item.menu_url,item.menu_url == "/admin/sensorzhouShanScreen" )
                  // if(item.menu_url == "/admin/sensorzhouShanScreen" ){
                  //   console.log(item.menu_url, '测试舟山1')
                  // }
                  if (
                    item.menu_url == "/admin/sensorFuSunScreen" &&
                    localStorage.getItem("company_id") != 2582225
                  ) {
                    // console.log(item.menu_url, '测试富顺');
                  } else if (
                    item.menu_url == "/admin/sensorPingWuScreen" &&
                    localStorage.getItem("company_id") != 2582275
                  ) {
                    // console.log(item.menu_url, '测试平武');
                    // menu_list = item;

                  } else if (
                    item.menu_url == "/admin/sensorzhouShanScreen" &&
                    localStorage.getItem("company_id") != 2582294
                  ) {
                    // console.log(item.menu_url, '测试舟山');
                    // menu_list = item;

                  } else if (
                    item.menu_url == "/admin/sensorDanLingScreen" &&
                    localStorage.getItem("company_id") != 2582269
                  ) {
                    // console.log(item.menu_url, '测试丹棱');
                    // menu_list = item;
                  } else {
                    menu_list = item;
                  }
                  return menu_list;
                })
                .filter((item) => item != null),
            };
            // console.log(data, '测试舟山');

            return data;
          });
        }

        // item.menu_url === to.path
        const has = menu_list.some((item) => { return to.path.includes(item.menu_url) });
        if (has) {
          //   if (to.query.company_id) {
          //     next();
          //     return;
          // };

          // if (from.query.company_id&&from.path!='/login') {
          //     let toQuery = JSON.parse(JSON.stringify(to.query));
          //     toQuery.company_id = from.query.company_id;
          //     next({
          //         path: to.path,
          //         query: toQuery
          //     })
          // } else {
          next()
          // }
        } else {
          Message({
            message: '非法操作',
            type: 'error',
            duration: 4 * 1000
          })
        }
      })
    }
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
